import React from 'react'
import classnames from 'classnames'

const FullPageBlock = (props) => {
  const classFromProps = props.className || '';
  let className = { "full-page-container" : "full-page-container" };
  if(classFromProps != '') {
    className[classFromProps] = classFromProps;
  }  

  return (<div
    className={classnames(className)}
    style={{
      backgroundColor: props.style.bgColor,
      color: props.style.textColor,
      height: props.style.height !== 'undefined' ? props.style.height : 'inherit',
      border: props.style.border !== 'undefined' ? props.style.border : 'none',
      borderBottom: 'none',
      overflow: props.style.overflow !== 'undefined' ? props.style.overflow : 'inherit'
    }}>
    { props.children }
  </div>)
}

export default FullPageBlock;
